import { inject } from '@angular/core';
import { SessionService } from './session.service';
import { toSignal } from '@angular/core/rxjs-interop';

export const injectUser$ = () => {
    return inject(SessionService).user$;
  };
  
  export const inject$User = () => {
    return toSignal(injectUser$());
  };
  
  