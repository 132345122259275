import { createEntityAdapter, EntityState } from '@ngrx/entity';
import {
  ContributorInfoDto,
  MessageDto,
  MessageInfoDto,
} from '@swagger/generated/humanresources';

export interface MessagesState {
  me: string | null;
  messageInfos: EntityState<MessageInfoDto>;
  messages: EntityState<MessageDto>;
  recipients: EntityState<ContributorInfoDto>;
}

export const messageInfosEntityAdapter = createEntityAdapter<MessageInfoDto>({
  selectId: (message: MessageInfoDto) => message.uId,
});

export const messagesEntityAdapter = createEntityAdapter<MessageDto>({
  selectId: (message: MessageDto) => message.uId,
});

export const recipientsEntityAdapter = createEntityAdapter<ContributorInfoDto>({
  selectId: (employee: ContributorInfoDto) => employee.uId,
});

export const initialMessagesState: MessagesState = {
  me: null,
  messageInfos: messageInfosEntityAdapter.getInitialState(),
  messages: messagesEntityAdapter.getInitialState(),
  recipients: recipientsEntityAdapter.getInitialState(),
};

export const FEATURE_NAME = 'feature/messages';

export const FEATURE_ACTION_PREFIX = 'Feature Messages';
