import { inject, Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
  fetchMessage,
  fetchMessageInfos,
  fetchRecipients,
  initAction,
  replyMessage,
  sendMessage,
} from './messages.actions';
import { MessageService } from '@swagger/generated/humanresources';
import {
  catchError,
  debounceTime,
  map,
  mergeMap,
  switchMap,
  } from 'rxjs';
import { SignalrHub } from '@paragondata/ngx-signalr';
import { AuthService, SessionService } from '@core/auth';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable()
export class MessagesEffects extends SignalrHub implements OnInitEffects {
  private actions$ = inject(Actions);

  private session = inject(SessionService);

  private messageService = inject(MessageService);

  ngrxOnInitEffects(): Action {
    try {
      // super.listen('consumeMessage').subscribe((message) => {
      //   console.log('consumeMessage', message);
      // });
    } catch (error) {
      // console.error('Error listening to consumeMessage', error);
    }

    return initAction();
  }

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initAction),
      mergeMap((_) => [fetchRecipients.request(), fetchMessageInfos.request()])
    )
  );

  fetchRecipients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchRecipients.request),
      debounceTime(1000),
      switchMap(() =>
        this.messageService
          .messageQueryReceipients({
            body: {},
          })
          .pipe(
            map((response) =>
              fetchRecipients.success({ recipients: response.result })
            ),
            catchError((error) => [fetchRecipients.failure({ error })])
          )
      )
    )
  );

  fetchMessageInfos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchMessageInfos.request, sendMessage.success),
      debounceTime(1000),
      switchMap(() =>
        this.messageService
          .messageQueryMyMessages({
            body: {},
          })
          .pipe(
            map((response) =>
              fetchMessageInfos.success({ messages: response.result })
            ),
            catchError((error) => [fetchMessageInfos.failure({ error })])
          )
      )
    )
  );

  fetchMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchMessage.request),
      mergeMap(({ messageUId }) =>
        this.messageService.messageGetMessage({ messageUId }).pipe(
          map((response) => fetchMessage.success({ message: response.result })),
          catchError((error) => [fetchMessage.failure({ error })])
        )
      )
    )
  );

  sendMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendMessage.request),
      switchMap(({ message }) =>
        this.messageService.messageCreateMessage({ body: message }).pipe(
          map((response) => sendMessage.success({ message: response.result })),
          catchError((error) => [sendMessage.failure({ error })])
        )
      )
    )
  );

  replyMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(replyMessage.request),
      switchMap(({ message, messageReceipientUId, messageUId }) =>
        this.messageService
          .messageReply({ body: message, messageReceipientUId, messageUId })
          .pipe(
            map((response) =>
              replyMessage.success({ message: response.result })
            ),
            catchError((error) => [replyMessage.failure({ error })])
          )
      )
    )
  );

  constructor(auth: AuthService) {
    super({
      url: 'https://hrp-test.paragon-data.net/hrp/v1/sgr/wrkfrc',
      enableAutomaticReconnect: true,
      httpOptions: {
        transport: 1,
        logMessageContent: true,
        skipNegotiation: true,
        accessTokenFactory: () => `${auth.getToken()}`,
      },
    });
  }
}
