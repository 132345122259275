import { __awaiter } from 'tslib';
import { HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { interval, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
class SignalrHub {
  constructor(options) {
    this._listenerCount = 0;
    let builder = new HubConnectionBuilder();
    builder = builder.withUrl(options.url, options.httpOptions);
    if (options === null || options === void 0 ? void 0 : options.automaticReconnect) {
      if (options.enableAutomaticReconnect) {
        builder = builder.withAutomaticReconnect(options.automaticReconnect);
      }
    }
    this._hubConnection = builder.build();
    interval(30000).subscribe(() => {
      this.checkConnection();
    });
  }
  get connected() {
    return this._hubConnection.state === HubConnectionState.Connected;
  }
  get connecting() {
    return this._hubConnection.state === HubConnectionState.Connecting;
  }
  get disconnected() {
    return this._hubConnection.state === HubConnectionState.Disconnected;
  }
  get disconnecting() {
    return this._hubConnection.state === HubConnectionState.Disconnecting;
  }
  get reconnecting() {
    return this._hubConnection.state === HubConnectionState.Reconnecting;
  }
  connect() {
    return __awaiter(this, void 0, void 0, function* () {
      if (this.connected || this.connecting || this.reconnecting) {
        return;
      }
      yield this._hubConnection.start().catch(err => console.error('SignalrHub.connect()', err));
    });
  }
  disconnect() {
    return this._hubConnection.stop().catch(err => console.error('SignalrHub.stop()', err));
  }
  checkConnection() {
    if (this._listenerCount > 0 && this.disconnected) {
      this.connect();
    }
  }
  listen(methodName) {
    return new Observable(observer => {
      const handler = data => {
        observer.next(data);
      };
      this._hubConnection.on(methodName, handler);
      this._listenerCount++;
      this.connect().catch(err => console.error('SignalrHub.listen()', err));
      return () => {
        this._hubConnection.off(methodName, handler);
        this._listenerCount++;
        if (this._listenerCount <= 0) {
          this.disconnect();
        }
      };
    }).pipe(shareReplay(1));
  }
}

/*
 * Public API Surface of ngx-signalr
 */

/**
 * Generated bundle index. Do not edit.
 */

export { SignalrHub };
