import { createReducer, on } from '@ngrx/store';
import {
  initialMessagesState,
  messageInfosEntityAdapter,
  messagesEntityAdapter,
  recipientsEntityAdapter,
} from './messages.state';
import {
  fetchMessage,
  fetchMessageInfos,
  fetchRecipients,
  sendMessage,
  setMe,
} from './messages.actions';

export const messageReducer = createReducer(
  initialMessagesState,
  on(setMe, (state, { me }) => ({ ...state, me })),
  on(fetchRecipients.success, (state, { recipients }) => {
    return {
      ...state,
      recipients: recipientsEntityAdapter.setAll(recipients, state.recipients),
    };
  }),
  on(fetchMessageInfos.success, (state, { messages }) => ({
    ...state,
    messageInfos: messageInfosEntityAdapter.setAll(
      messages,
      state.messageInfos
    ),
  })),
  on(fetchMessage.success, (state, { message }) => ({
    ...state,
    messages: messagesEntityAdapter.addOne(message, state.messages),
  })),
  on(sendMessage.success, (state, { message }) => ({
    ...state,
    messages: messagesEntityAdapter.addOne(message, state.messages),
  }))
);
