import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  FEATURE_NAME,
  messageInfosEntityAdapter,
  MessagesState,
  recipientsEntityAdapter,
} from './messages.state';

export const selectMessageState =
  createFeatureSelector<MessagesState>(FEATURE_NAME);

export const selectMessageInfoEntityState = createSelector(
  selectMessageState,
  (s) => s.messageInfos
);

export const selectMessageEntityState = createSelector(
  selectMessageState,
  (s) => s.messages
);

export const selectRecipientEntityState = createSelector(
  selectMessageState,
  (s) => s.recipients
);

const messageInfoSelectors = messageInfosEntityAdapter.getSelectors(
  selectMessageInfoEntityState
);

const messageSelectors = messageInfosEntityAdapter.getSelectors(
  selectMessageEntityState
);

const recipientSelectors = recipientsEntityAdapter.getSelectors(
  selectRecipientEntityState
);

export const selectMe = createSelector(selectMessageState, (s) => s.me);

export const selectMessageInfos = messageInfoSelectors.selectAll;

export const selectRecipients = createSelector(
  recipientSelectors.selectAll,
  selectMe,
  (recipients, me) => {
    return recipients.filter((r) => r.uId !== me);
  }
);

export const selectMessages = messageSelectors.selectAll;


export const selectMessage = (messageUId: string) => createSelector(
  messageSelectors.selectEntities,
  (entities) => entities[messageUId]
);

export const selectRecipient = (recipientUId: string) => createSelector(
  recipientSelectors.selectEntities,
  (entities) => entities[recipientUId]
);
